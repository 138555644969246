import React from 'react'
import {useTranslations} from 'next-intl'

import ExtraMetaHead from '../components/modules/ExtraMetaHead'
import Home from '../core/tools/Miscellaneous/Home'
import i18config from '../next-i18next.config'

export async function getStaticProps({locale}) {
  return {
    props: {
      locales: i18config.i18n.locales,
      isMobile: false,
      messages: (await import(`../i18n/messages/${locale}.json`)).default
    },
  }
}


export default function IndexPage() {
  const t = useTranslations()

  return (
    <>
      <ExtraMetaHead
        description={t('home.description')}
        keywords={t('home.keywords')}
      />

      <Home/>
    </>
  )
}
